/* eslint-disable no-console */

import Rollbar from 'rollbar';
import {customIgnore} from './helpers';

let rollbarInstance = null;
let hasRegistered = false;

/**
 * Registers and initializes the Rollbar SDK.
 *
 * @param {Rule[]} ignoreRules - (Optional) A set of rules used to extend the `checkIgnore` function.
 *
 *                                `checkIgnore` is a hook that Rollbar gives us and is invoked with
 *                                3 arguments (see JsDoc of that function below).
 *
 *                                Inside `checkIgnore`, we have access to the arguments that were passed
 *                                to `Rollbar.log`, and we also have access to the payload that will be sent
 *                                to the Rollbar API.
 *
 *                                Rollbar's docs advise us that it is safe to use both arguments for matching:
 *                                https://docs.rollbar.com/docs/reduce-noisy-javascript-errors
 *
 *                                For convenience in matching, we construct an object with both `rollbarArgs`
 *                                and `rollbarPayload` as properties, and use this as a base for matching against
 *                                the rules.
 *
 *                                A rule is defined like this:
 *
 *                                type SingleRule = {
 *                                  path: string;
 *                                  matchers: Array<string | number | RegExp>;
 *                                };
 *
 *                                type GroupRule = {
 *                                  group: SingleRule[];
 *                                };
 *
 *                                type IgnoreRule = SingleRule | GroupRule;
 *
 *                                The `path` string should have the full path of the value we want to test.
 *                                - If that value belongs to the args passed to the Rollbar.log function, the path
 *                                  should start with `rollbarArgs`.
 *                                - If that value belongs to the Rollbar API payload, the path should start with `rollbarPayload`.
 *
 *                                The `matchers` array can contain either Strings, Numbers or RegExps, and will match them accordingly.
 *
 *                                A group of rules consists of multiple rules (duh) which all of those rules must match in order for
 *                                the group to match.
 *
 *
 *                                Examples:
 *
 *                                // The following will match "Script error" against the first argument passed to Rollbar.log.
 *                                {
 *                                  path: 'rollbarArgs[0]',
 *                                  matchers: ['Script error.']
 *                                }
 *
 *                                // The following will match "Script error" against the `message` property of the second argument
 *                                // passed to Rollbar.log.
 *                                {
 *                                  path: 'rollbarArgs[1].message',
 *                                  matchers: ['Script error.']
 *                                }
 *
 *                                // The following will match "Script error" against the `body.trace.exception.message` (nested) property
 *                                // of the payload that will be sent to Rollbar API.
 *                                {
 *                                  path: 'rollbarPayload.body.trace.exception.message',
 *                                  matchers: ['Script error.']
 *                                }
 *
 *                                // The following will match `428` against `body.message.extra.responseStatus` (nested) property AND
 *                                // "subscription" against `body.message.extra.error.type` of the payload that will be sent to Rollbar API.
 *                                {
 *                                  group: [
 *                                    {path: 'rollbarPayload.body.message.extra.responseStatus', matchers: [428]},
 *                                    {path: 'rollbarPayload.body.message.extra.error.type', matchers: [/subscription/]}
 *                                  ]
 *                                }
 *
 *                                Please note that `rollbarArgs` is an array (because it contains all the args passed to Rollbar.log)
 *                                while `rollbarPayload` is an object.
 */
export const register = ({
  enabled,
  mode = __ENV__,
  environment = __ENV__,
  code_version = __VERSION__,
  accessToken = __ROLLBAR_ACCESS_TOKEN__,
  hostSafeList = [],
  scrubTelemetryInputs = true,
  scrubFields = [],
  ignoreRules = []
}) => {
  if (hasRegistered) {
    console.warn('Rollbar has already initialized');
    return rollbarInstance;
  }
  hasRegistered = true;
  const isRollbarEnabled = enabled !== undefined ? enabled : mode === 'production' || mode === 'staging';

  const rb = new Rollbar({
    enabled: isRollbarEnabled,
    accessToken,
    hostSafeList,
    scrubFields,
    captureIp: 'anonymize',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment,
      client: {
        javascript: {
          code_version,
          guess_uncaught_frames: true,
          source_map_enabled: true
        }
      }
    },
    /**
     * Is called by Rollbar before sending an error to the server. It allows us to ignore
     * errors based on custom rules.
     * @param {boolean} isUncaught - Caution: When using the Rollbar.js logging method, this is set to `false`.
     * @param {string | Object} args - The args passed to the Rollbar.js logging method. If the error is uncaught
     *                          and is from an unhandled rejection, the args parameter contains the Promise object.
     *                          It may also contain the initial error message as string.
     * @param {Object} payload - The payload that will be sent to the Rollbar API
     * @returns {boolean}
     */
    checkIgnore: (isUncaught, args, payload) => {
      const [messageOrError = ''] = args;
      const error = messageOrError && typeof messageOrError === 'object' ? messageOrError : payload;
      const message = error.message ? error.message.toLowerCase() : '';
      const isNetworkError = message.indexOf('network error') === 0;
      const isScriptError = message.indexOf('script error') === 0;
      const isWebsocketError = message.indexOf('websocket error') === 0;
      const shouldIgnoreError = customIgnore(ignoreRules, args, payload);
      return isNetworkError || isScriptError || isWebsocketError || shouldIgnoreError;
    },
    scrubTelemetryInputs
  });

  rollbarInstance = rb;
  if (typeof window !== 'undefined') {
    window.Rollbar = rb;
  }

  return rb;
};

export default () => {
  if (!hasRegistered) {
    console.warn('Using rollbar without initialization');
    return null;
  }

  return rollbarInstance;
};
