/**
 * Creates a Google Tag Manager emitter object that can be used to emit custom events and pageviews.
 *
 * @param {*} promise
 * @returns
 */
const createGtmEmitter = (promise = Promise.resolve()) => {
  return {
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'event', ...);
     *
     * @param {string} category - The event category, e.g. 'video'.
     * @param {string} action -The event action, e.g. 'view'.
     * @param {string} label - The event label, e.g. 'Nyan Cat'.
     * @param {Object} extraProps - Extra (custom) properties to pass with the event.
     */
    event: (category, action, label, extraProps) => ({
      send: () =>
        promise.then(
          () =>
            window &&
            window.dataLayer &&
            window.dataLayer.push({event: 'event', category, action, label, ...extraProps})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'heapEvent', ...);
     *
     * @param {string} action -The event action, e.g. 'view'.
     * @param {Object} extraProps - Extra (custom) properties to pass with the event.
     */
    heapEvent: (action, extraProps) => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'heapEvent', action, extraProps})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'heapIdentify', ...);
     *
     * @param {string} userId - The ID the user will be identified with.
     */
    heapIdentify: userId => ({
      send: () =>
        promise.then(() => window && window.dataLayer && window.dataLayer.push({event: 'heapIdentify', userId}))
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'resetHeapIdentity');
     */
    resetHeapIdentity: () => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'resetHeapIdentity', userId: null})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'addHeapEventProperties', ...);
     *
     * @param {Object} properties - The Heap event properties to be added on each emitted event.
     */
    addHeapEventProperties: properties => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'addHeapEventProperties', properties})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'removeHeapEventProperty', ...);
     *
     * @param {string} propertyName - The name of the Heap event property to be removed.
     */
    removeHeapEventProperty: propertyName => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'removeHeapEventProperty', propertyName})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'clearHeapEventProperties', ...);
     */
    clearHeapEventProperties: () => ({
      send: () =>
        promise.then(() => window && window.dataLayer && window.dataLayer.push({event: 'clearHeapEventProperties'}))
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'hotjarIdentify', ...);
     *
     * @param {string} userId - The ID the user will be identified with.
     * @param {Object} properties - The user properties to be sent to Hotjar.
     */
    hotjarIdentify: (userId, hjProperties) => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'hotjarIdentify', userId, hjProperties})
        )
    }),
    /**
     * Emits a custom Google Tag Manager event. Equivalent to window.dataLayer.push({event: 'resetHotjarIdentity');
     */
    resetHotjarIdentity: () => ({
      send: () =>
        promise.then(
          () => window && window.dataLayer && window.dataLayer.push({event: 'resetHotjarIdentity', userId: null})
        )
    }),
    /**
     * Emits a Google Tag Manager pageview event. Equivalent to window.dataLayer.push({event: 'pageview', ...);
     *
     * @param {string} path - The pageview path that was visited, e.g. '/' or '/my/application'.
     */
    pageview: path => ({
      send: () => promise.then(() => window && window.dataLayer && window.dataLayer.push({event: 'pageview', path}))
    }),
    /**
     * Emits a Google Tag Manager variable. Equivalent to window.dataLayer.push({key: value});
     *
     * @param {string} key - The name of the Google Tag Manager variable.
     * @param {string} value - The value of the Google Tag manager variable.
     */
    variable: (key, value) => ({
      send: () => key && promise.then(() => window && window.dataLayer && window.dataLayer.push({[key]: value}))
    })
  };
};

export default createGtmEmitter;
