import url from 'helpers/url';
import {initializeGtmConsent} from './gtmConsent';
import createGtmEmitter from './gtmEmitter';

const BASE_URL = 'https://www.googletagmanager.com';

const _analytics = ({id, gtm_auth, gtm_preview, gtm_cookies_win = 'x'} = {}) =>
  new Promise(resolve => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    const script = window.document.createElement('script');

    const parameters = {
      id,
      gtm_auth,
      gtm_preview,
      gtm_cookies_win
    };

    script.src = url.withBaseURL(BASE_URL).withPath('gtm.js').withParameters(parameters).build();
    script.async = true;
    window.document.head.appendChild(script);
    const noscript = window.document.createElement('noscript');
    const frame = window.document.createElement('iframe');
    frame.height = '0';
    frame.width = '0';
    frame.style.display = 'none';
    frame.style.visibility = 'hidden';
    frame.src = url.withBaseURL(BASE_URL).withPath('ns.html').withParameters(parameters).build();
    noscript.appendChild(frame);
    window.document.body.appendChild(noscript);
    resolve();
  });

/**
 * Factory method for constructing a gtm client for analytics via Google Tag Manager.
 *
 * @param {Object} configuration - The configuration.
 * @param {string} configuration.id - The GTM id of the project.
 * @param {string} configuration.gtm_auth - The gtm_auth used to authenticate with GTM.
 * @param {string} configuration.gtm_preview - The URL gtm_preview.
 * @param {string} configuration.gtm_cookies_win - The URL gtm_cookies_win, defaults to 'x'.
 * @param {string} configuration.gtm_consent_mode_enabled - Enables the new Google Consent Mode. Defaults to false.
 */
export default ({id, gtm_auth, gtm_preview, gtm_cookies_win = 'x', gtm_consent_mode_enabled = false} = {}) => {
  window.gtag_enable_tcf_support = gtm_consent_mode_enabled;
  if (gtm_consent_mode_enabled) {
    initializeGtmConsent();
  }
  const analytics = _analytics({id, gtm_auth, gtm_preview, gtm_cookies_win});

  return createGtmEmitter(analytics);
};
