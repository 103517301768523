import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Animation from '../base';
import commonPropTypes from '../commonPropTypes';
import getAsUnitString from 'shared/ui/helpers/getAsUnitString';
import styles from './styles.scss';

const getAsDegreesString = value => getAsUnitString(value, 'deg');

const Rotate = ({
  from = '0deg',
  to = '360deg',
  delay = 0,
  duration = 300,
  iterations = 1,
  direction = 'normal',
  easing = 'ease-in-out',
  fill = 'forwards',
  ...props
}) => {
  const keyframes = [
    from ? {transform: `rotate(${getAsDegreesString(from)})`} : null,
    {transform: `rotate(${getAsDegreesString(to)})`}
  ];

  const timing = {
    fill,
    delay,
    duration,
    iterationStart: 0,
    iterations,
    direction,
    easing
  };

  return (
    <Animation
      {...props}
      className={clsx(styles['rotate-animation'], props.className)}
      timing={timing}
      keyframes={keyframes}
    />
  );
};

Rotate.displayName = 'Animation.Rotate';

Rotate.propTypes = {
  ...commonPropTypes,
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  animate: PropTypes.bool
};

export default Rotate;
