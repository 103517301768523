import get from 'lodash/get';

const ruleMatches = (matcher, value = '') => {
  if (matcher instanceof RegExp) {
    return matcher.test(value);
  }

  if (typeof matcher === 'number') {
    return matcher === value;
  }

  if (value instanceof Error) {
    value = value.toString();
  }

  return value.includes(matcher);
};

const hasAnyRuleMatch = (obj, rule) => {
  const value = get(obj, rule.path);
  return rule.matchers.some(matcher => ruleMatches(matcher, value));
};

/*
 * `customIgnore` is used to further extend the list of ignored errors from Rollbar.
 * It is an agnostic helper function that given a set of rules or group of rules,
 * the args passed to Rollbar log function, and the payload that will be sent to
 * Rollbar API, tests each rule or group of rules against these values and returns `true` if at least
 * one of the rules or group of rules is satisfied.
 *
 * type Rule = {path: string; matchers: Array<String | number | RegExp>;}
 */
export const customIgnore = (rules, rollbarArgs = [], rollbarPayload = {}) => {
  const matchAgainst = {
    rollbarArgs,
    rollbarPayload
  };

  return rules.some(rule => {
    if (rule.group) {
      const groupRules = rule.group;
      return groupRules.every(groupRule => hasAnyRuleMatch(matchAgainst, groupRule));
    }

    return hasAnyRuleMatch(matchAgainst, rule);
  });
};
