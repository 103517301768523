import React from 'react';
import PropTypes from 'prop-types';
import Ref from 'shared/ui/behaviors/ref';
import commonPropTypes from '../commonPropTypes';
import _isScreenshotTesting from '../../../../../config/webpack/environment/isScreenshotTesting';

const isScreenshotTesting = _isScreenshotTesting();

const defaultAnimationDelay = 0;
const defaultAnimationDuration = 300;

const computeEndAnimationStyles = (keyframes, timing) => {
  const directedKeyframes = timing.direction === 'reverse' ? keyframes.reverse() : keyframes;
  return directedKeyframes.reduce((acc, frame) => ({...acc, ...frame}), {});
};

const getFinalStyles = (keyframes, timing, otherStyles) => {
  return {...otherStyles, ...computeEndAnimationStyles(keyframes, timing)};
};

const createEvents = (eventNames, callback, props) => {
  const events = Object.create(null);
  const capitalize = text => text && `${text.charAt(0).toUpperCase()}${text.substr(1)}`;

  eventNames.split(' ').forEach(eventName => {
    if (!eventName) {
      return;
    }

    let name;
    if (eventName.startsWith('on')) {
      name = `on${capitalize(eventName.substr(2))}`;
    } else {
      name = `on${capitalize(eventName)}`;
    }

    events[name] = e => {
      const existingEventHandler = typeof props[name] === 'function';
      if (existingEventHandler) {
        existingEventHandler(e);
      }
      callback(e);
    };
  });
  return events;
};

const matchMediaSupported = Boolean(window.matchMedia);
const animateSupported = Boolean(document.createElement('div').animate);
const isBrowserSupported = matchMediaSupported && animateSupported;

class Animation extends React.PureComponent {
  constructor(...props) {
    super(...props);

    if (!isBrowserSupported || isScreenshotTesting) {
      this.state.isAnimationEnabled = false;
      return;
    }

    this.prefersReducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    this.state.isAnimationEnabled = !this.prefersReducedMotionQuery.matches;
  }

  state = {
    isAnimationEnabled: false
  };

  componentDidMount() {
    if (!isBrowserSupported) {
      return;
    }

    if (this.prefersReducedMotionQuery) {
      this.prefersReducedMotionQuery.addListener(this.handlePreferNoMediaChange);
    }
  }

  componentWillUnmount() {
    if (!isBrowserSupported) {
      return;
    }
    if (this.animation) {
      this.animation.cancel();
      this.animation.removeEventListener('finish', this.handleCompleted);
      this.animation.onfinish = undefined;
      this.animation.oncancel = undefined;
    }

    if (this.prefersReducedMotionQuery) {
      this.prefersReducedMotionQuery.removeListener(this.handlePreferNoMediaChange);
    }
  }

  handlePreferNoMediaChange = e => {
    this.setState({prefersReducedMotionQuery: !e.matches});
  };

  handleUnsupportedBrowserRef = el => {
    if (!el) {
      return;
    }
  };

  handleCompleted = e => {
    if (this.animation.playbackRate > 0) {
      this.props.handleCompleted(e);
    }
  };

  handleRef = el => {
    if (!el) {
      return;
    }
    this.el = el;

    if (!this.state.isAnimationEnabled) {
      this.props.handleCompleted();
      return;
    }

    this.animation = el.animate(this.getAnimationKeyFrames(el), this.getAnimationTiming());
    this.animation.addEventListener('finish', this.handleCompleted);
    if (!this.props.animate) {
      this.animation.pause();
    }
  };

  createEventsToAnimate = props => {
    const {startAnimate = ''} = this.props;
    return createEvents(
      startAnimate,
      () => {
        this.animation.pause();
        this.animation.playbackRate = Math.abs(this.animation.playbackRate);
        this.animation.play();
      },
      props
    );
  };

  createEventsToStopAnimate = props => {
    const {stopAnimate = ''} = this.props;
    return createEvents(
      stopAnimate,
      () => {
        this.animation.pause();
        this.animation.playbackRate = -Math.abs(this.animation.playbackRate);
        this.animation.play();
      },
      props
    );
  };

  getAnimationTiming = () => {
    const {timing, delay, duration} = this.props;

    return {
      ...timing,
      delay: delay || timing.delay || defaultAnimationDelay,
      duration: duration || timing.duration || defaultAnimationDuration
    };
  };

  getAnimationKeyFrames = el => {
    const {keyframes, partialKeyframes, timing} = this.props;
    const validKeyframes = keyframes.filter(frame => Boolean(frame));
    const isPartialKeyframes = (validKeyframes && validKeyframes.length === 1) || partialKeyframes;
    if (!isPartialKeyframes) {
      return keyframes;
    }
    const partialFrames = validKeyframes || partialKeyframes;
    const animationEndStyles = computeEndAnimationStyles(partialFrames, timing);
    const elementInitialStyles = getComputedStyle(el);
    const fromStartFrame = Object.keys(animationEndStyles).reduce((acc, style) => {
      acc[style] = elementInitialStyles[style];
      return acc;
    }, {});

    return [fromStartFrame, ...partialFrames];
  };

  render() {
    const {keyframes, partialKeyframes, timing, children, className, animate} = this.props;

    const child = React.Children.only(children);
    const componentStyle = this.state.isAnimationEnabled
      ? child.props.style
      : getFinalStyles(keyframes || partialKeyframes, timing, child.props.style);
    const additionalProps = {
      style: componentStyle,
      'data-animate': animate,
      className: `${className} ${child.props.className || ''}`,
      ...this.createEventsToAnimate(child.props),
      ...this.createEventsToStopAnimate(child.props)
    };

    return <Ref $ref={this.handleRef}>{React.cloneElement(child, additionalProps)}</Ref>;
  }
}

Animation.displayName = 'Animation';

Animation.defaultProps = {
  handleCompleted: () => {}
};

Animation.propTypes = {
  ...commonPropTypes,
  /**
   * An array of objects describing how CSS properties change during animation.
   * Similar to CSS keyframes
   * The number of items in array will spread equal throughout the timeline to define
   * the proper timing a property change would occur
   * For example a property that will start at 100px, at 50% of the animation time will
   * reach to 130px and will end at 200px.
   * @example
   * ```js
   *  [{width: '100px'}, {width: '130px'} {width: `200px`}]
   * ```
   *
   * If need to define a different timeline offset use `offset` prop in one of the items.
   * @example
   * [...{width: '130px', offset: 0.3}, ...]
   */
  keyframes: PropTypes.array.isRequired,
  /**
   * Like keyframes but the initial keyframe rules should be calculated from components styles
   */
  partialKeyframes: PropTypes.array,
  /**
   * The timing metadata describing the animation
   */
  timing: PropTypes.object.isRequired
};

export default Animation;
